import React from "react";
import classnames from "classnames";

/* Import Local Styles */
import * as styles from "./section.module.scss";

const Section = ({ id, title, content }) => {
  return title ? (
    <section id={id} className={classnames("section", styles.section)}>
      <div className={classnames("title", styles.title)}>{title}</div>
      <div className={classnames("content", styles.content)}>{content}</div>
    </section>
  ) : (
    <section id={id} className={classnames("section", styles.section)}>
      {content}
    </section>
  );
};

export default Section;
