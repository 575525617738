import React from "react";
import classnames from "classnames";

/* Import Local Styles */
import * as styles from "./pdf.module.scss";

/* Import Local Assets */
import PdfSvgSrc from "./assets/pdf.svg";
import DownloadSvgSrc from "./assets/download.svg";

const PDF = ({
  index,
  pdf: {
    title,
    description,
    asset: { url },
  },
}) => {
  return (
    <a
      href={url}
      className={classnames("pdf", styles.pdf)}
      target="_blank"
      rel="noreferrer"
    >
      <p className={classnames("index", styles.index)}>
        {String(index + 1).padStart(2, "0")}.
      </p>
      <p className={classnames("title", styles.title)}>{title}</p>
      <div className={classnames("icon", styles.icon)}>
        <img src={PdfSvgSrc} alt="Pdf Icon" />
        <img src={DownloadSvgSrc} alt="Pdf Icon" />
      </div>
    </a>
  );
};

export default PDF;
