import React from "react";
import classnames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
/* Import Local Styles */
import * as styles from "./collection.module.scss";

/* Import Local Assets */
import RightArrowSvgSrc from "./assets/right-arrow.svg";

const Collection = () => {
  const {
    allShopifyProduct: { edges: products },
  } = useStaticQuery(graphql`
    query CollectionQuery {
      allShopifyProduct {
        edges {
          node {
            id
            title
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
                }
              }
            }
            handle
            priceRangeV2 {
              maxVariantPrice {
                amount
                currencyCode
              }
            }
            publishedAt
          }
        }
      }
    }
  `);

  return (
    <ul className={classnames("collection", styles.collection)}>
      {products.map(({ node: product }) => (
        <li className={classnames("item", styles.item)} key={product.id}>
          <Link to={`/products/${product.handle}`}>
            <GatsbyImage
              image={getImage(product.featuredImage.localFile)}
              alt={product.featuredImage.altText}
            />
            <div className={classnames("details", styles.details)}>
              {/* Title */}
              <div>
                <p>{product.title}</p>
                <p>
                  {product.priceRangeV2.maxVariantPrice.currencyCode}
                  {product.priceRangeV2.maxVariantPrice.amount}
                </p>
              </div>
              {/* Release Date */}
              <div>
                <p>Released:</p>
                <p>{new Date(product.publishedAt).toDateString()}</p>
              </div>
              {/* View Item */}
              <p to={`/products/${product.handle}`}>
                <span>View</span>{" "}
                <img src={RightArrowSvgSrc} alt="Right Arrow" />
              </p>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Collection;
