import React, { Fragment } from "react";
import { Link as ScrollLink, animateScroll } from "react-scroll";
import { useStaticQuery, graphql } from "gatsby";
import classnames from "classnames";
import { StaticImage } from "gatsby-plugin-image";

/* Import Global Hooks */
import useContact from "@hooks/useContact";

/* Import Global Components */
import Collection from "@components/collection/collection";
import Pdf from "@components/pdf/pdf";
import SEO from "@components/seo/seo";

/* Import Local Components */
import Section from "./index/components/section/_section";

/* Import Local Styles */
import * as styles from "./index.module.scss";

/* Import Local Assets */
import NoTricksLargeLogoSvgSrc from "./assets/no-tricks-large-logo.svg";
import RodeoClownSvgSrc from "./assets/rodeo-clown.svg";
import InformationBackgroundSvgSrc from "./index/assets/information-background.svg";
import ArrowUpSvgSrc from "./assets/arrow-up.svg";

const IndexPage = () => {
  const {
    sanityResearch: { pdfs },
    sanityLinks: { links },
    sanityNoTricks: { description: noTricksDescription },
  } = useStaticQuery(graphql`
    query ResearchQuery {
      sanityResearch(_id: { eq: "research" }) {
        pdfs {
          _key
          title
          description
          asset {
            url
          }
        }
      }
      sanityLinks(_id: { eq: "links" }) {
        id
        links {
          _key
          name
          address
        }
      }
      sanityNoTricks(_id: { eq: "noTricks" }) {
        description
      }
    }
  `);
  const {
    streetAddress,
    zipCode,
    unitNumber,
    street,
    state,
    phoneNumber,
    emailAddress,
    city,
    buildingNumber,
  } = useContact();

  return (
    <Fragment>
      <div className={styles.spacer} />
      {/* INFO SECTION */}
      <Section
        id="info"
        content={
          <div className={styles.information}>
            <p className={styles.heroCopy}>{noTricksDescription}</p>
            <div>
              <div className={classnames("copy", styles.copy)}>
                <div className={classnames("back", styles.back)}>
                  <button
                    onClick={() => {
                      animateScroll.scrollToTop();
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                    }}
                  >
                    <img
                      src={ArrowUpSvgSrc}
                      alt="Up Arrow"
                      style={{ marginRight: "2rem" }}
                    />
                    <span>Back</span>
                  </button>
                </div>
                <div className={classnames("services", styles.services)}>
                  <p>Services:</p>
                  <p>Artist & Repertoire</p>
                  <p>Creative Direction</p>
                  <p>Management</p>
                  <p>Label Services</p>
                </div>
                <div className={classnames("address", styles.address)}>
                  <p>General Offices:</p>
                  <p>{street}</p>
                  {buildingNumber ? (
                    <p>
                      {buildingNumber} {city}
                    </p>
                  ) : (
                    <p>{city}</p>
                  )}
                  <p>
                    {state} {zipCode}
                  </p>
                  {unitNumber ? <p>{`#${unitNumber}`}</p> : null}
                </div>
                <div className={classnames("contact", styles.contact)}>
                  <p>For service call:</p>
                  <a href={`tel:+${phoneNumber}`}>+{phoneNumber}</a>
                  <br />
                  <p>General Inquiry:</p>
                  <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                </div>
                <img
                  src={InformationBackgroundSvgSrc}
                  className={styles.backgroundImage}
                />
                <div
                  style={{
                    width: "100%",
                  }}
                  className={styles.companyPortraitsContainer}
                >
                  <StaticImage
                    src="./assets/company_portraits_unoptimized.png"
                    layout="fullWidth"
                    alt="Drawing of No Tricks Personnel"
                    // style={{
                    //   width: "100%",
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
      {/* SHOP SECTION */}
      <Section id="shop" title={<h2>Shop</h2>} content={<Collection />} />
      {/* LINKS SECTION */}
      <Section
        id="links"
        title={<h2>Links</h2>}
        content={
          <p className={classnames("links", styles.links)}>
            {links.map((link, index) => {
              if (index === links.length - 1) {
                return (
                  <span key={link._key}>
                    <a href={link.address} target="_blank" rel="noreferrer">
                      {link.name}
                    </a>
                  </span>
                );
              } else {
                return (
                  <span key={link._key}>
                    <a href={link.address} target="_blank" rel="noreferrer">
                      {link.name},{" "}
                    </a>
                  </span>
                );
              }
            })}
          </p>
        }
      />
      {/* RESEARCH SECTION */}
      <Section
        id="research"
        title={
          <h2>
            No Tricks Research &<br />
            Recordings select document index.
            <br />
            <br />
            2019-{new Date().getFullYear()}
          </h2>
        }
        content={
          <div className={classnames("research", styles.research)}>
            <ul>
              {pdfs.map((pdf, index) => {
                return (
                  <li key={pdf._key}>
                    <Pdf index={index} pdf={pdf} />
                  </li>
                );
              })}
            </ul>
            <p className={classnames("policies", styles.policies)}>
              INFORMATIONAL RESERCH RECORDED AND RESEARCHED BY NO TRICKS® We
              provide marketing and advertising products and services for our
              clients, and may collect and process information about individuals
              at the direction of our clients ("Client Data"). Client Data has
              historically included contact data, demographic data, social
              handles, content, internet activity, and location data, among
              other information. Our processing of Client Data is governed by
              the terms of our service agreements with our client, and not this
              Privacy Policy. We are not responsible for how our client treat
              the information we collect on their behalf, and we recommend you
              review their own privacy policies.
            </p>
          </div>
        }
      ></Section>
      {/* TRUCK  */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <StaticImage
          className={styles.truck}
          src="./assets/NT_SERVICE_TRUCK_RESIZED_COMPRESSED.jpg"
          alt="No Tricks Truck"
        />
      </div>
    </Fragment>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
